<template>
  <header class="background">
      <div class="header__content">
            <h1 v-html="t('title')"></h1>
            <div class="header__actions">
                <button class="btn btn--blue" @click="smoothScroll('how')">
                    {{t('cta.mission')}}
                </button>
                <button class="btn btn--white" @click="smoothScroll('footer')">
                    {{t('cta.contact')}}
                </button>
            </div>
      </div>
      <div class="header__line"></div>
        <button class="header__scroll" @click="smoothScroll('how')" aria-label="scroll down">
              <Svg className="header__scroll--svg" icon="scroll"/>
          </button>
      <img src="@/assets/img/doe-mountain.svg"   alt="mountain background svg" class="background__image">
  </header>
</template>

<script>

import Svg from './utils/Svg';
import {smoothScroll} from '../composables/scroll';

import {useI18n} from 'vue-i18n';

export default {
    components:{Svg},
    setup(){

          const {t} = useI18n({
            messages:{
              en: require('@/locales/en/header.json'),
              fr: require('@/locales/fr/header.json')
            }
        })

        return{
            smoothScroll,
            t
        }
    }

}
</script>

<style lang="scss">
@import '@/style/main';

.background{
    margin-top: $head-height;
    position: relative;
    background-color: $color-bck-blue-light;
    height: calc(100vh - #{$head-height});
    display: flex;
    flex-direction: column;

    border-bottom: 2rem solid $color-blue-main;

    @include respond(phone){
        height: calc(90vh - #{$head-height});
    }
    &__image{
        margin-top: auto;
        width: 100%;
    }


}

$header-top-large: 22%;
$header-left-large: 26.5%;

$header-top: 20%;
$header-left: 20%;

$header-top-phone: 15%;
$header-left-phone: 10%;

.header{

    &__content{
        position: absolute;
        top: $header-top;
        left: $header-left;
        padding-right: 6rem;

        @include respond(phone){
            top: $header-top-phone;
            left: $header-left-phone;
        }
        @include respond(large-desktop){
            top: $header-top-large;
            left: $header-left-large;
        }

        & h1{
            font-size: 3.5rem;
            font-weight: 300;
            font-style: italic;
            line-height: 4.5rem;

            @include respond(large-desktop){
                 font-size: 4.5rem;
                 line-height: 6.5rem;
            }

            @include respond(small){
                font-size: 3rem;
                line-height: 3.5rem;
            }
        }

        & strong{
            font-weight: 700;
        }
    }




    &__actions{
        margin-top: 2.5rem;
        display: flex;

        & button{
            &:not(:last-child){
                margin-right: 1.6rem;
                @include respond(phone){
                    margin-right: 0;
                    margin-bottom: 1.6rem;
                }
            }
        }

        @include respond(phone){
            flex-direction: column;
        }
    }

    &__line{
        position: absolute;
        top: calc(#{$header-top} + .6rem);
        left: calc(#{$header-left} - 2rem);
        height: calc(100% - #{$header-top} - .6rem);
        width: 5px;
        background-color: $color-blue-main;

         @include respond(phone){
           top: calc(#{$header-top-phone} + .6rem);
            left: calc(#{$header-left-phone} - 2rem);
            height: calc(100% - #{$header-top-phone} - .6rem);
        }
        @include respond(large-desktop){
           top: calc(#{$header-top-large} + .6rem);
            left: calc(#{$header-left-large} - 2rem);
            height: calc(100% - #{$header-top-large} - .6rem);
        }
    }

    &__scroll{
        @include buttonNoStyle();
        position: absolute;
        top: 95%;
        left: 50%;

        transform: translate(-50%, -50%);
        color:$color-white;
        animation: scroll 4s infinite;

        @keyframes scroll {
            0% {
                transform: translate(-50%, -50%)
            }
            5% {
                transform: translate(-50%, -25%)
            }
            10%{
                transform: translate(-50%, -75%)
            }
            15% {
                transform: translate(-50%, -50%)
            }
        }

        &--svg{
            @include svg(4rem);
        }

        @include respond(phone){
            display: none;
        }
    }
}

</style>